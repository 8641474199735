import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Tos = ({location}) => (
  <Layout location={location}>
    <Helmet title="Allgemeine Geschäftsbedingungen Austria Guides" />
    <Container className="py-lg-5 mt-5 mb-5">
      <Row className="align-items-center pt-5 pt-lg-7">
        <Col>
          <h1 className="text-uppercase">Allgemeine Geschäftsbedingungen</h1>
          <h6 className="text-uppercase">für Fremdenführer</h6>
        </Col>
      </Row>
      <Row className="text-center mt-5">
        <Col>
          <a href="/agb-austria-guides.pdf" className="btn btn-danger">Als PDF herunterladen</a>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Tos